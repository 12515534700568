import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { map, compose, pick, when, always } from 'ramda';
import { Box, theme as T, Button } from 'soot-sprites';
import fetchIndemnitorValidation, {
  resetIndemnitorValidationErrors,
} from '../actions/fetchIndemnitorValidation';
import {
  saveIndemnitorField,
  createIndemnitors,
  addIndemnitor,
  removeIndemnitor,
  changePage,
  fetchFormValidation,
} from '../actions';
import { PageHeading, Fields, FormNavigation } from '../components';
import {
  assocValueInStateWithField,
  pickDataFromElement,
  assocErrorWithField,
  useDocumentTitle,
  activationHandler,
} from '../utils';
import { REJECTED } from '../routes';

function Indemnitor({
  pageNumber: stringPageNumber,
  form,
  indemnitorUserFields,
  indemnitorFields,
  createIndemnitors,
  navigate,
  saveIndemnitorField,
  fetchIndemnitorValidation,
  indemnitorValidation,
  indemnitorCreate,
  resetIndemnitorValidationErrors,
  addIndemnitor,
  removeIndemnitor,
  fetchFormValidation,
  title,
}) {
  useDocumentTitle(title);
  const pageNumber = +stringPageNumber;
  const canIncrement = pageNumber < indemnitorFields.response.layout.length - 1;
  const nextPageRelativeUrl = canIncrement
    ? `../${pageNumber + 1}`
    : `../../signature`;
  const indemnitorsCreated =
    indemnitorCreate.response.indemnitor_handles.length > 0;

  function next(event) {
    event.preventDefault();

    if (!indemnitorsCreated) {
      fetchIndemnitorValidation(indemnitorUserFields)
        .then(pageHasErrorsPredicate => !pageHasErrorsPredicate(pageNumber))
        .then(canContinue => {
          if (canContinue) {
            if (!canIncrement) {
              createIndemnitors().then(creditAccepted =>
                creditAccepted
                  ? fetchFormValidation().then(navigate(nextPageRelativeUrl))
                  : navigate(`/${REJECTED.uri}`)
              );
            } else {
              resetIndemnitorValidationErrors();
              navigate(nextPageRelativeUrl);
            }
          }
        });
    } else {
      navigate(nextPageRelativeUrl);
    }
  }

  const add = event => {
    event.preventDefault();
    addIndemnitor();
  };

  const remove = i => event => {
    event.preventDefault();
    removeIndemnitor(i);
  };

  const disableField = x => ({ ...x, disabled: true });

  return form.response.needs_indemnity &&
    !indemnitorFields.inFlight &&
    indemnitorFields.response ? (
    <>
      <Box as="form" width={1} css="text-align: left;" noValidate>
        {indemnitorUserFields.indemnitors.map((userIndemnitor, i) => (
          <Fragment key={i}>
            <PageHeading mt={i === 0 ? 0 : [3, 4]} mb={[4, 5]}>
              <span>Add Stakeholders </span>
              <span
                css={`
                  color: ${T.black};
                `}
              >
                / Owner {i + 1}
              </span>
              {i == 0 && pageNumber == 0 ? (
                <Box
                  css={`
                    color: ${T.black};
                    font-size: 14px;
                    font-weight: 300;
                  `}
                  mb={3}
                  mt={4}
                >
                  Your bond will guarantee (to the obligee) that we will cover
                  any potential claims up to the bond amount. Surety bonds are a
                  little different from insurance policies, though. We follow
                  with standard practice that requires that you agree to
                  reimburse us against losses.
                  <Box mt={3} css="font-weight: 500; font-size: 14px;">
                    All owners must be listed unless they own less than 10% of
                    the business.
                  </Box>
                </Box>
              ) : null}
            </PageHeading>
            {i == 0 && pageNumber == 0 ? (
              <>
                <div css="text-align: right;">
                  <Button
                    px={3}
                    disabled={indemnitorsCreated}
                    {...activationHandler(add)}
                    {...Button.filterProps.variant.tertiaryAlt}
                    {...Button.filterProps.size.small}
                  >
                    + Add Owner
                  </Button>
                </div>
              </>
            ) : null}
            {i > 0 && pageNumber == 0 ? (
              <div css="text-align: right;">
                <Button
                  disabled={indemnitorsCreated}
                  {...activationHandler(remove(i))}
                  {...Button.filterProps.variant.tertiaryAlt}
                  {...Button.filterProps.size.small}
                >
                  - Remove Owner
                </Button>
              </div>
            ) : null}
            <Fields
              onChange={event =>
                saveIndemnitorField(pickDataFromElement(event.target), i)
              }
              fields={map(
                compose(
                  when(always(indemnitorsCreated), disableField),
                  assocValueInStateWithField(userIndemnitor),
                  assocErrorWithField(indemnitorValidation.response.errors[i])
                )
              )(indemnitorFields.response.fields[pageNumber])}
            />
          </Fragment>
        ))}
      </Box>
      <FormNavigation
        onBackBtnClick={() =>
          navigate(
            pageNumber
              ? `../${pageNumber - 1}`
              : `../../entry/${form.response.pages.length}`
          )
        }
        onClick={next}
        loading={indemnitorCreate.inFlight}
      >
        {indemnitorCreate.inFlight
          ? 'Running Credit Check'
          : pageNumber === 0
          ? 'Continue'
          : 'Request a Quote'}
      </FormNavigation>
    </>
  ) : null;
}

const mapStateToProps = pick([
  'form',
  'formValidation',
  'userFormLead',
  'indemnitorFields',
  'indemnitorUserFields',
  'indemnitorValidation',
  'indemnitorCreate',
]);
const mapDispatchToProps = {
  saveIndemnitorField,
  fetchIndemnitorValidation,
  fetchFormValidation,
  createIndemnitors,
  resetIndemnitorValidationErrors,
  removeIndemnitor,
  addIndemnitor,
  changePage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Indemnitor);
