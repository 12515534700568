import React, { useState, useEffect } from 'react';
import { Input } from 'soot-sprites';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import InvalidFeedback from './InvalidFeedback';

const TypedComponent = ({
  type,
  options = [],
  display,
  placeholder,
  ...props
}) => {
  switch (type) {
    case 'zip':
      return (
        <MaskedInput
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
          type="text"
          pattern="^(\d{5}?)$"
          placeholder="e.g. 02115"
          render={(ref, props) => <Input ref={ref} {...props} />}
          {...props}
        />
      );
    case 'int':
      return (
        <Input
          type="number"
          min={`${props.range.min}`}
          max={`${props.range.max}`}
          pattern={`^[${props.range.min}-${props.range.max}$`}
          placeholder={placeholder}
          {...props}
        />
      );
    case 'date':
      // Between today and 60 days from now
      return <Input type="date" {...props} />;
    case 'email':
      return (
        <Input
          type="email"
          pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
          placeholder="jane.doe@domain.com"
          {...props}
        />
      );
    case 'ssn':
      return (
        <MaskedInput
          mask={[
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          type="text"
          pattern="^(\d{3}-\d{2}-\d{4})|(\d{9})$"
          placeholder="XXX-XX-XXXX"
          render={(ref, props) => <Input ref={ref} {...props} />}
          {...props}
        />
      );
    case 'phone':
      // Matches a phone number with at least 7 digits(not counting an extension)
      // in any number of digit groups separated by spaces or dashes, and possibly
      // enclosed in parentheses.
      // Source: https://rgxdb.com/r/4KFA61EJ
      return (
        <MaskedInput
          mask={[
            '(',
            /[2-9]/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          type="tel"
          placeholder="(555) 555-5555"
          pattern="^(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?)(?:[ -]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?))*(?:[ ]?(?:x|ext)\.?[ ]?\d{1,5})?$"
          render={(ref, props) => <Input ref={ref} {...props} />}
          {...props}
        />
      );
    case 'checkbox': {
      const {
        width,
        title,
        validFeedback,
        invalidFeedback,
        value = false,
        ...checkBoxProps
      } = props;
      return (
        <>
          <Checkbox
            title={display}
            placeholder={null}
            checked={!!value}
            {...checkBoxProps}
          />
          <div
            css="font-size: 12px; margin-top: 2px; font-weight: 400;"
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
        </>
      );
    }
    case 'option':
    case 'state':
    case 'shipping': {
      return (
        <Input as="select" {...props}>
          {props.title ? <option value="">{props.title}</option> : null}
          {options.map(({ value, name }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </Input>
      );
    }
    default:
      return <Input type="text" placeholder={placeholder} {...props} />;
  }
};

const feedbackTextForType = (field, serverInvalidFeedback) =>
  serverInvalidFeedback ||
  ({
    zip: 'This field requires a valid five (5) digit US ZIP code',
    int: `This field requires an integer between ${
      field.range ? field.range.min : 0
    } and ${field.range ? field.range.max : 0}`,
    date: 'This field requires a date',
    ssn: 'This field requires a nine (9) digit social security number',
    phone:
      'This field requires a three (3) digit area code followed by a seven (7) digit phone number',
    email: 'This field requires a valid email address',
    checkbox: 'This field must be checked in order to continue',
    option: 'This field requires an option to be selected',
    shipping: 'This field requires a shipping method to be selected',
    state: 'This field requires a state be selected',
  }[field.type] ||
    'This field is marked as required');

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  margin-left: 8px;
  display: inline-block !important;
  width: auto !important;
  height: auto !important;
`;

function Field({
  value = '',
  description,
  when,
  name,
  invalidFeedback: serverInvalidFeedback,
  groupName,
  required,
  onChange,
  ...props
}) {
  const [invalidFeedback, setInvalidFeedback] = useState(false);
  const [validFeedback, setValidFeedback] = useState(false);

  useEffect(() => {
    setInvalidFeedback(!!serverInvalidFeedback);
  }, [serverInvalidFeedback]);

  return (
    <label
      htmlFor={name}
      css={`
        display: block;
        margin-top: 8px;
      `}
    >
      <span
        css={`
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
        `}
      >
        {props.display}
      </span>
      {!required ? (
        <span css="font-style: italic; font-size: 12px;"> - Optional</span>
      ) : null}
      <TypedComponent
        // Add important because property fails to hold on iOS
        css={`
          display: block;
          width: 100%;
          height: 48px;
          background-color: #fff;
          margin-top: 2px;
          font-size: 18px;
        `}
        id={name}
        name={name}
        title={description}
        invalidFeedback={invalidFeedback}
        validFeedback={validFeedback}
        placeholder={description}
        aria-invalid={invalidFeedback ? 'true' : undefined}
        required={required}
        iconSize="24px"
        onFocus={() => setValidFeedback(false)}
        onBlur={event => setInvalidFeedback(!event.target.validity.valid)}
        onChange={event => {
          if (invalidFeedback) {
            const { valid } = event.target.validity;
            setInvalidFeedback(!valid);
            setValidFeedback(valid);
          }
          onChange(event);
        }}
        value={value}
        {...props}
      />
      <InvalidFeedback visible={invalidFeedback}>
        {feedbackTextForType(props, serverInvalidFeedback)}
      </InvalidFeedback>
    </label>
  );
}

Field.displayName = 'Field';
export default Field;
