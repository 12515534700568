import React from 'react';
import { Heading, theme as T } from 'soot-sprites';

export default ({ children, ...props }) => (
  <Heading
    fontSize={['24px', '26px']}
    fontWeight="500"
    pb={[3, 5]}
    css={`
      color: ${T.primary(600)};
      border-bottom: 1px solid ${T.gray(100)};
    `}
    {...props}
  >
    {children}
  </Heading>
);
