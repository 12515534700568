import { apiRequest } from '../utils';

export const INDEMNITOR_FIELDS_REQUESTED = 'INDEMNITOR_FIELDS_REQUESTED';
export const INDEMNITOR_FIELDS_RECEIVED = 'INDEMNITOR_FIELDS_RECEIVED';
export const INDEMNITOR_FIELDS_FAILED = 'INDEMNITOR_FIELDS_FAILED';

export const indemnitorsRequest = () => ({ type: INDEMNITOR_FIELDS_REQUESTED });
export const indemnitorsReceive = json => ({
  type: INDEMNITOR_FIELDS_RECEIVED,
  response: json,
});
export const indemnitorsFail = error => ({
  type: INDEMNITOR_FIELDS_FAILED,
  error,
});

export default () => dispatch => {
  dispatch(indemnitorsRequest());
  return apiRequest
    .get(`indemnitors`)
    .then(response => dispatch(indemnitorsReceive(response.data)))
    .catch(err => dispatch(indemnitorsFail(err)));
};
