import React, { useEffect, useRef } from 'react';
import { Container } from 'soot-sprites';
import { pick } from 'ramda';
import { connect } from 'react-redux';
import { destroyApplicationState } from '../actions';
import { useInlineLoader } from '../utils';
function Success({ partner, form, destroyApplicationState }) {
  useInlineLoader(true, false);
  // Save persistent copy of stateful fields so we can safely destroy app state
  // without changing content on the page
  const stateRef = useRef({ partner, form });
  useEffect(() => {
    destroyApplicationState();
  }, [destroyApplicationState]);
  return (
    <Container py={[3, 5]} px={[3, 5]}>
      <h2>Order Received</h2>
      <h3>Amount Paid: ${stateRef.current.form.response.price}.00</h3>
      <p>
        Thank you for placing your order! We will send a confirmation of your
        order via email. Charges appearing on your credit card will be reflected
        as UCS Insurance Company.
      </p>
      <p>
        If you have any questions, please send email to{' '}
        <a href={`mailto:${stateRef.current.partner.response.support_email}`}>
          {stateRef.current.partner.response.support_email}
        </a>{' '}
        or call us at{' '}
        <a href={`tel:${stateRef.current.partner.response.phone}`}>
          {stateRef.current.partner.response.phone}
        </a>
        .
      </p>
      <p>You can now safely close this window.</p>
    </Container>
  );
}

const mapStateToProps = pick(['partner', 'form']);
const mapDispatchToProps = {
  destroyApplicationState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Success);
