import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Flex, Heading, Box, theme as T } from 'soot-sprites';
import {
  map as _map,
  addIndex,
  find,
  propEq,
  compose,
  propOr,
  prop,
} from 'ramda';
import { fetchPdf } from '../actions';
import uuid from 'uuid/v4';
import styled, { css } from 'styled-components';
import { ENTRY, PAYMENT } from '../routes';
import { useDocumentTitle } from '../utils';
import { evaluate, newEnv } from '../utils/scheme';
import { PageHeading, FormNavigation } from '../components';
const Pdf = React.lazy(() => import('../components/Pdf'));
const map = addIndex(_map);

const PageTitle = styled(Flex)`
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

const getDisplayValue = ({ type, options }, value) => {
  switch (type) {
    case 'checkbox':
      return value ? 'Yes' : 'No';
    case 'option':
    case 'state':
    case 'shipping':
      return compose(
        propOr('Not found', 'name'),
        find(propEq('value', value))
      )(options);
    default:
      return value;
  }
};

const StyledBox = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${T.gray(100)};
  overflow: hidden;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
`;
const borderTop = css`
  border-top: 1px solid ${T.gray(100)};
`;
const Section = ({ children, width = [1, 0.475] }) => (
  <Box mt={[4, 6]} width={width} as="section">
    {children}
  </Box>
);
const Field = ({ value, display }, i) => (
  <Box css={i && borderTop} key={uuid()}>
    <Box
      css={`
        background-color: ${T.blueGray(50)};
        font-weight: 500;
        text-transform: uppercase;
        font-size: 12px;
      `}
      width={1}
      py={2}
      px={3}
    >
      {display}
    </Box>
    <Box p={3}>{value}</Box>
  </Box>
);

function Review({
  userFields,
  form: {
    response: { pages, fields, name, needs_indemnity },
  },
  pdf,
  fetchPdf,
  title,
  navigate,
}) {
  useDocumentTitle(title);
  useEffect(() => {
    fetchPdf();
  }, [fetchPdf, userFields]);
  return (
    <>
      <PageHeading>
        <div>Review</div>
        <p
          css={`
            max-width: 560px;
            font-size: 14px;
            font-weight: 300;
            color: ${T.black};
            margin-bottom: 0px;
          `}
        >
          Review your bond and order information carefully. Changes after
          ordering may incur a $50 change fee.
        </p>
      </PageHeading>
      <Box
        mt={[4, 5]}
        css={`
          text-align: right;
        `}
      >
        <FormNavigation
          onClick={() => navigate(`../${PAYMENT.uri}`)}
          hideBackBtn
          {...Button.filterProps.size.large}
        >
          Continue to Last Step
        </FormNavigation>
      </Box>
      <React.Suspense fallback={<div>Loading PDF Preview...</div>}>
        <Pdf pdf={pdf} formName={name} userFields={userFields} />
      </React.Suspense>
      <Flex
        css={`
          flex-flow: row wrap;
          justify-content: space-between;
        `}
      >
        {map((fields, pageIndex) => (
          <Section key={uuid()}>
            <PageTitle>
              <Heading fontSize="22px" fontWeight="500">
                {pages[pageIndex]}
              </Heading>
              <Button
                css={`
                  font-size: 12px;
                  height: 32px;
                  border-radius: 4px;
                `}
                ml={3}
                width="112px"
                onClick={() =>
                  navigate(`../${ENTRY.uri}/${pageIndex + 1}`, {
                    state: { fromReview: true },
                  })
                }
                {...Button.filterProps.variant.secondary}
              >
                Change
              </Button>
            </PageTitle>
            <StyledBox mt={[2, 3]}>
              {fields
                .map(x => ({
                  ...x,
                  value: getDisplayValue(x, userFields[x.name]),
                }))
                .filter(f => !f.when || evaluate(newEnv(userFields), f.when))
                .filter(prop('value'))
                .map(Field)}
            </StyledBox>
          </Section>
        ))(fields)}
      </Flex>
      <FormNavigation
        onBackBtnClick={() =>
          navigate(
            needs_indemnity ? '../indemnitor/1' : `../entry/${pages.length}`
          )
        }
        onClick={() => navigate(`../${PAYMENT.uri}`)}
        {...Button.filterProps.size.large}
      >
        Continue to Last Step
      </FormNavigation>
    </>
  );
}

const mapStateToProps = ({ userFields, form, pdf }) => ({
  userFields,
  form,
  pdf,
});
const mapDispatchToProps = { fetchPdf };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Review);
