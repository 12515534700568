import {
  FORM_REQUESTED,
  FORM_RECEIVED,
  FORM_FAILED,
} from '../actions/fetchForm';
import { FORM_VALIDATION_RECEIVED } from '../actions/fetchFormValidation';
import { map, prop, omit } from 'ramda';
import layoutToMatrix from '../utils/layoutToMatrix';

export default function form(
  state = {
    response: {
      id: '',
      layout: [],
      needs_indemnity: false,
      fields: [],
      pages: [],
      state: '',
      name: '',
      price_range: [0, 0],
      valid_for: null,
      price_addons: {},
      price: 0,
    },
    inFlight: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case FORM_REQUESTED:
      return {
        ...state,
        inFlight: true,
      };
    case FORM_RECEIVED: {
      const { layout, fields: fieldMap, price } = action.response;
      return {
        ...state,
        inFlight: false,
        error: null,
        response: {
          ...action.response,
          price: Number(price),
          pages: map(prop('title'))(layout),
          fields: layoutToMatrix(fieldMap)(layout),
        },
      };
    }
    case FORM_FAILED:
      return {
        ...state,
        inFlight: false,
        error: action.error,
      };
    case FORM_VALIDATION_RECEIVED: {
      const { validation_errors, userformId, ...response } = action.response;
      return {
        ...state,
        response: {
          ...state.response,
          ...omit(
            ['validation_errors', 'userformId', 'jwt', 'indemnitor'],
            action.response
          ),
          price: Number(
            response.price === 0 ? state.response.price : response.price
          ),
        },
      };
    }
    default:
      return state;
  }
}
