export const INDEMNITOR_ADD = 'INDEMNITOR_ADD';

export const indemnitorAdd = () => ({
  type: INDEMNITOR_ADD,
});

export default () => (dispatch, getState) => {
  const {
    indemnitorFields: {
      response: { range },
    },
    indemnitorUserFields: { indemnitors },
  } = getState();
  if (indemnitors.length > range.max - 1) {
    return;
  }
  dispatch(indemnitorAdd());
};
