import {
  INDEMNITOR_FIELDS_REQUESTED,
  INDEMNITOR_FIELDS_RECEIVED,
  INDEMNITOR_FIELDS_FAILED,
} from '../actions/fetchIndemnitors';
import { map, prop } from 'ramda';
import layoutToMatrix from '../utils/layoutToMatrix';

export default function indemnitorFields(
  state = {
    response: {
      layout: [],
      indemnitor_handles: [],
    },
    inFlight: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INDEMNITOR_FIELDS_REQUESTED:
      return {
        ...state,
        inFlight: true,
        error: null,
      };
    case INDEMNITOR_FIELDS_RECEIVED: {
      const { layout, fields: fieldMap } = action.response;
      return {
        ...state,
        inFlight: false,
        response: {
          ...action.response,
          pages: map(prop('title'))(layout),
          fields: layoutToMatrix(fieldMap)(layout),
        },
      };
    }
    case INDEMNITOR_FIELDS_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
