import { USER_FORM_RECEIVED } from '../actions/fetchUserForm';
import { USER_FIELD_SAVE, USER_FIELD_DELETE } from '../actions/saveUserField';
import { FORM_RECEIVED } from '../actions/fetchForm';
import { filter, compose, map, omit } from 'ramda';
import { typeFieldValue } from '../utils';

export default function userFields(
  state = {
    term: '',
  },
  action
) {
  switch (action.type) {
    case USER_FIELD_SAVE:
      return {
        ...state,
        ...action.userField,
      };
    case USER_FORM_RECEIVED:
      return action.response.form;
    case USER_FIELD_DELETE:
      return omit(action.names, state);
    case FORM_RECEIVED:
      return {
        ...state,
        ...compose(
          // Add default values to state so that scheme dependent fields are rendered correctly
          map(({ type, default: value }) => typeFieldValue(type, value)),
          filter(
            ({ name, default: defaultValue }) =>
              hasNoValue(state[name]) && !hasNoValue(defaultValue)
          )
        )(action.response.fields),
      };
    default:
      return state;
  }
}

const hasNoValue = x =>
  x === null || typeof x === 'undefined' || x.length === 0;
