import fetchFormValidation from './fetchFormValidation';
import {
  findPage,
  findPageIndex,
  uriSegment,
  lastChar,
  errorInPageFields,
} from '../utils';
import { navigate } from '@reach/router';

export const NAVIGATION_REQUESTED = 'NAVIGATION_REQUESTED';
export const NAVIGATION_SUCCEEDED = 'NAVIGATION_SUCCEEDED';
export const NAVIGATION_FAILED = 'NAVIGATION_FAILED';

export const navigationRequest = () => ({
  type: NAVIGATION_REQUESTED,
});
export const navigationSuccess = (uri, toPageUri) => ({
  type: NAVIGATION_SUCCEEDED,
  toPageUri,
  uri,
});
export const navigationFail = uri => ({
  type: NAVIGATION_FAILED,
  uri,
});

export default ({ to = '', uri }, opts) => (dispatch, getState) => {
  const { page, form, partner } = getState();
  const relativeUri = uriSegment(uri);
  const toPageUri = !to.length ? nextPageUri(relativeUri, page.pages) : to;
  const absoluteUrl = `/${partner.response.id}/${
    form.response.id
  }/${toPageUri}`;
  const currentPage = findPage(relativeUri, page.pages);
  dispatch(navigationRequest());
  if (currentPage.needsValidation) {
    dispatch(fetchFormValidation(relativeUri)).then(response => {
      const pageIndex = Number(lastChar(uri)) - 1;
      const pageValid = !errorInPageFields(response.data.validation_errors)(
        form.response.fields[pageIndex]
      );
      if (pageValid) {
        dispatch(navigationSuccess(relativeUri, toPageUri));
        navigate(absoluteUrl, opts);
      } else {
        dispatch(navigationFail(relativeUri));
      }
    });
  } else {
    dispatch(navigationSuccess(relativeUri, toPageUri));
    navigate(absoluteUrl, opts);
  }
};

const nextPageUri = (uri, pages) => {
  const currentPageIndex = findPageIndex(uri, pages);
  const nextPageIndex =
    currentPageIndex < pages.length - 1
      ? currentPageIndex + 1
      : currentPageIndex;
  return pages[nextPageIndex].uri;
};
