import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { createMiddleware } from 'redux-beacon';
import { FORM_RECEIVED } from './actions/fetchForm';
import { PAYMENT_RECEIVED, PAYMENT_REQUESTED } from './actions/makePayment';
import { RESTORE_PAGES } from './actions/restorePages';

const emitForm = action => ({
  event: 'Checkout Start',
  category: action.response.name,
  state: action.response.state,
});
const emitSessionRestore = () => ({
  event: 'Session Restore',
});
const emitTransactionDetails = (action, prevState, { form, userFormLead }) => ({
  transactionId: userFormLead.response.userFormId,
  transactionTotal: form.response.price,
  transactionProducts: [
    {
      name: form.response.name,
      sku: form.response.id,
      price: form.response.price,
      quantity: 1,
    },
  ],
});
const emitCheckoutComplete = () => ({
  event: 'Checkout Complete',
});

export default createMiddleware(
  {
    [FORM_RECEIVED]: emitForm,
    [RESTORE_PAGES]: emitSessionRestore,
    [PAYMENT_REQUESTED]: emitTransactionDetails,
    [PAYMENT_RECEIVED]: emitCheckoutComplete,
  },
  GoogleTagManager()
);
