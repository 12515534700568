export const INDEMNITOR_REMOVE = 'INDEMNITOR_REMOVE';

export const indemnitorRemove = index => ({
  type: INDEMNITOR_REMOVE,
  index,
});

export default index => (dispatch, getState) => {
  const {
    indemnitorFields: {
      response: { range },
    },
    indemnitorUserFields: { indemnitors },
  } = getState();

  if (indemnitors.length > range.min && 0 < index < indemnitors.length) {
    dispatch(indemnitorRemove(index));
  }
};
