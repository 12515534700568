import React, { useEffect } from 'react';
import { pick } from 'ramda';
import { connect } from 'react-redux';
import { theme as T, Text, Box, Flex } from 'soot-sprites';
import { fetchFormValidation } from '../actions';
import { activationHandler, useDocumentTitle } from '../utils';
import { FormNavigation } from '../components';
import { IoMdTrophy, IoMdWarning } from 'react-icons/io';
import styled from 'styled-components';

const commas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const EMAIL_INSTRUCTIONS =
  "All owners should check their emails and sign the indemnity agreement. Once we receive the signed documents, you're only a few clicks away from getting your bond!";
const CONTINUE_INSTRUCTIONS =
  'Thank you for signing the indemnity agreement. Please proceed to view a sample of your bond by clicking below.';
const Owner = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 16px 24px;
  border-top: 1px solid ${T.gray(100)};
  background-color: ${T.gray(50)};
  span {
    flex: 1;
  }
  dt {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
  }
  dd {
    margin: 0;
    font-size: 14px;
  }
`;

function Signature({
  fetchFormValidation,
  formValidation,
  indemnitorUserFields,
  form,
  restore,
  navigate,
  title,
}) {
  useDocumentTitle(title);
  const signedIndemnityAgreement =
    formValidation.response.indemnityError === null ||
    formValidation.response.indemnityError === '';

  useEffect(() => {
    if (!signedIndemnityAgreement) {
      const timer = setInterval(() => {
        fetchFormValidation('entry/1');
      }, 3000);
      return () => clearInterval(timer);
    }
  }, [fetchFormValidation, signedIndemnityAgreement]);

  const next = event => {
    event.preventDefault();
    if (signedIndemnityAgreement) {
      navigate('../review');
    }
  };

  return (
    <>
      {form.response.price !== 0 ? (
        <Box
          css={`
            border: 1px solid #dbe4e5;
            background-color: #f2f8f9;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
          `}
        >
          <Text
            fontSize={['24px', '26px']}
            fontWeight="500"
            color="#529761"
            p={[3, 4]}
            css={`
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #dbe4e5;
            `}
          >
            <div>Congratulations, you're approved!</div>
            <IoMdTrophy css="width: 40px; height: 40px; color: #bec0c1;" />
          </Text>
          <Text
            fontWeight="300"
            fontSize={['18px', '20px']}
            css={`
              display: block;
              color: ${T.gray(500)};
            `}
            p={[4, 5]}
          >
            Your {form.response.name} Bond will cost{' '}
            <Text fontWeight="700" color="#529761">
              ${commas(form.response.price)}
            </Text>{' '}
            for{' '}
            <Text fontWeight="700" color="#529761">
              ${commas(form.response.amount)}
            </Text>{' '}
            worth of coverage.
          </Text>
        </Box>
      ) : null}
      <Box
        css={`
          border: 1px solid ${T.gray(100)};
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
        `}
        mt={[4, 5]}
      >
        <Flex
          css={`
            flex-flow: row nowrap;
            align-items: center;
            background-color: ${T.blueGray(50)};
          `}
        >
          <Box p={[3, 4]} aria-hidden>
            <IoMdWarning
              css={`
                flex-shrink: 0;
                height: 56px;
                width: 56px;
                color: ${T.gray(500)};
              `}
            />
          </Box>
          <Text
            aria-live="polite"
            fontSize={['15px', '16px']}
            css={`
              color: ${T.gray(500)};
              text-align: left;
              display: block;
              border-left: 1px solid ${T.gray(100)};
            `}
            p={[3, 4]}
          >
            {!signedIndemnityAgreement
              ? EMAIL_INSTRUCTIONS
              : CONTINUE_INSTRUCTIONS}
          </Text>
        </Flex>
        {indemnitorUserFields.indemnitors.length ? (
          <Box
            as="dl"
            css={`
              margin: 0;
            `}
          >
            {indemnitorUserFields.indemnitors.map(
              ({ first_name, last_name, email }, i) => (
                <Owner key={email}>
                  <span>
                    <dt>Name</dt>
                    <dd>
                      {first_name} {last_name}
                    </dd>
                  </span>
                  <span>
                    <dt>Email</dt>
                    <dd>{email}</dd>
                  </span>
                  <span>
                    <dt>Status</dt>
                    <dd>
                      {formValidation.response.indemnitors[i] &&
                      formValidation.response.indemnitors[i].status === 'signed'
                        ? 'Done!'
                        : formValidation.response.indemnitors[i] &&
                          formValidation.response.indemnitors[i].status ===
                            'mocked'
                        ? 'Mocked'
                        : 'Waiting for signature...'}
                    </dd>
                  </span>
                </Owner>
              )
            )}
          </Box>
        ) : null}
      </Box>
      <FormNavigation
        onBackBtnClick={() => navigate('../indemnitor/1')}
        loading={!signedIndemnityAgreement}
        LoadingComponent={() => null}
        {...activationHandler(next)}
      >
        {signedIndemnityAgreement ? 'Continue' : 'Waiting for signatures'}
      </FormNavigation>
    </>
  );
}

const mapStateToProps = pick([
  'formValidation',
  'form',
  'restore',
  'indemnitorUserFields',
]);
const mapDispatchToProps = {
  fetchFormValidation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signature);
