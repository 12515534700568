export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (err) {
    console.warn('Failed to save state to local storage');
  }
};

export const destroyState = () => {
  try {
    sessionStorage.removeItem('state');
    localStorage.removeItem('origin');
  } catch (err) {
    console.warn('Failed to wipe application state from local storage');
  }
  return undefined;
};
