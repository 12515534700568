import React from 'react';
import { render } from 'react-dom';
import Routes from './routes';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { StripeProvider } from 'react-stripe-elements';
import theme from './styles/theme';
import store from './store';
import * as serviceWorker from './serviceWorker';
import GlobalStyles from './styles/global';
import 'normalize.css';
import 'typeface-montserrat/index.css';

render(
  <Provider store={store}>
    <StripeProvider apiKey={process.env.STRIPE_PK}>
      <ThemeProvider theme={theme}>
        <>
          <Routes />
          <GlobalStyles />
        </>
      </ThemeProvider>
    </StripeProvider>
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
