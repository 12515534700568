import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fetchPartner,
  fetchForm,
  changePage,
  destroyApplicationState,
} from '../actions';
import {
  Container,
  Link as LinkBase,
  Flex,
  Box,
  Text,
  theme as T,
} from 'soot-sprites';
import { Progress } from '../components';
import sfaa from '../images/sfaa.png';
import feather from '../images/feather.svg';
import styled from 'styled-components';
import media from '../styles/media';
import { includes, find, always, compose, defaultTo } from 'ramda';
import { useInlineLoader } from '../utils';
import { navigate } from '@reach/router';
import { EXPIRED } from '../routes';
import { categoriesWithIndemnity, categoriesWithoutIndemnity } from '../routes';

const Logo = styled.img`
  width: 64px;
  height: 64px;
  filter: grayscale(100%);
`;
const Link = styled(LinkBase).attrs({
  role: 'listitem',
})`
  font-size: 14px;
  color: ${T.gray(500)};
  font-weight: 400;
  white-space: nowrap;
`;
const BrandedLink = styled(LinkBase)`
  color: ${T.black};
  font-size: 22px;
  font-weight: 400;
  display: flex;
  max-width: 256px;
  white-space: nowrap;
  ${media.sm`
    font-size: 24px;
    margin-right: 16px;
  `}
`;
const Links = styled.ul`
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 0;
  padding: 0;
  max-width: 328px;
`;
const Logos = styled.div`
  display: none;
  text-align: right;
  padding-top: 8px;
  max-width: 240px;
  flex: 1 1;
  ${media.md`
    padding-top: 0;
    display: block;
  `}
`;

function Layout({
  children,
  form,
  fetchForm,
  formId,
  fetchPartner,
  partnerId,
  partner,
  page,
  changePage,
  location,
  destroyApplicationState,
  formValidation,
  restore,
}) {
  const categories = form.response.needs_indemnity
    ? categoriesWithIndemnity
    : categoriesWithoutIndemnity;
  const ready = partner.response.id && !!form.response.fields.length;
  useInlineLoader(ready, false);

  useEffect(() => {
    // Router does not consistently pass formId, retriggering effect even if Id is the same
    // Add additional safety
    if (form.response.id !== formId && form.inFlight === false) {
      // When a past user looks at a new bond, they may have state from
      // last checkout process; destroy to avoid bugs
      destroyApplicationState();
      fetchForm(formId);
    }
  }, [
    fetchForm,
    formId,
    form.response.id,
    form.inFlight,
    destroyApplicationState,
  ]);

  useEffect(() => {
    fetchPartner(partnerId);
  }, [fetchPartner, partnerId]);

  useEffect(() => {
    // we check that the user has signed an indemnity agreement
    // and has this restored this page in another window before expiring
    if (
      restore.response === null &&
      localStorage.getItem('origin') === 'restored'
    ) {
      navigate(`/${EXPIRED.uri}`);
    }
  }, [restore.response, formValidation]);

  const { pageNumberFn, numPagesFn } = compose(
    defaultTo({ pageNumberFn: always(null), numPagesFn: always(null) }),
    find(({ pathMatch }) => location.pathname.match(pathMatch))
  )(categories);
  const numCategoryPages = numPagesFn(form.response.pages);
  const categoryPageNumber = pageNumberFn(location.pathname);
  return ready ? (
    <Flex css="height: 100vh;" flexDirection="column">
      <Box
        as="header"
        css={`
          display: flex;
          flex-direction: row nowrap;
          align-items: center;
          justify-content: space-between;
          background-color: ${T.blueGray(50)};
          min-height: 60px;
          border-bottom: 1px solid ${T.gray(100)};
          ${media.sm`
            min-height: 72px;
          `}
        `}
        px={[3, 5]}
        width={1}
      >
        <Text fontWeight="400" fontSize={['18px', '24px']}>
          <span
            css={`
              color: ${T.primary(700)};
              font-weight: 500;
            `}
          >
            {form.response.state}{' '}
          </span>
          <span>{form.response.name} Bond</span>
        </Text>
        <Box
          as="img"
          css={`
            width: 28px;
            height: 28px;
            ${media.sm`
                width: 32px;
                height: 32px;
              `}
          `}
          src={feather}
          alt="Self Service Surety dot com"
        />
      </Box>
      <Container
        css={`
          flex: 1 0 auto;
          ${media.sm`
        justify-content: center;
        `}
          ${includes('review', location.pathname)
            ? 'max-width: 1192px;'
            : undefined}
        `}
        width={1}
        id="page-content"
        py={null}
        pt={[3, 5]}
        pb={[4, 6]}
        px={[3, 5]}
        role="main"
      >
        <Progress
          partnerId={partnerId}
          formId={formId}
          form={form}
          page={page}
          changePage={changePage}
          location={location}
          mb={[5, 6]}
          mt={[3, 4]}
        />
        {children}
        {numCategoryPages && categoryPageNumber ? (
          <Box
            mt={[4, 6]}
            css={`
              font-size: 14px;
              color: ${T.gray(400)};
              height: 20px;
              text-align: center;
            `}
          >
            Step {categoryPageNumber} of {numCategoryPages}
          </Box>
        ) : null}
      </Container>
      <Flex
        px={[3, 5]}
        width={1}
        css={`
          overflow: hidden;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-top: 1px solid ${T.gray(100)};
          background-color: #fff;
          flex-shrink: 0;
          height: 96px;
          ${media.sm`
            justify-content: space-between;
            flex-direction: row;
            height: 120px;
          `}
        `}
        role="contentinfo"
      >
        <BrandedLink href="https://selfservicesurety.com">
          <span>Self-service Surety</span>
          <img src={feather} alt="Self Service Surety dot com" />
        </BrandedLink>
        <Links>
          <Link href="https://www.selfservicesurety.com/about">About Us</Link>
          <Link href="https://www.selfservicesurety.com/privacy" ml={[3, 4]}>
            Privacy & Security
          </Link>
          <Link href="https://selfservicesurety.com/terms" ml={[3, 4]}>
            Terms of Service
          </Link>
        </Links>
        <Logos>
          <Logo
            src={sfaa}
            alt="Registered with the Surety & Fidelity Association of America"
          />
        </Logos>
      </Flex>
    </Flex>
  ) : null;
}

const mapStateToProps = ({ form, partner, page, formValidation, restore }) => ({
  form,
  partner,
  page,
  formValidation,
  restore,
});
const mapDispatchToProps = {
  fetchForm,
  fetchPartner,
  changePage,
  destroyApplicationState,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
