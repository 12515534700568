import { createGlobalStyle } from 'styled-components';
import media from './media';

export default createGlobalStyle`
  html, body {
    height: 100vh;
    width: 100%;
    min-width: 320px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    color: #000;
    background-color: #FCFCFC;
    font: 100% 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.4;
    ${media.sm`
      line-height: 1.5;
      font-size: 16px;
    `};
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  p {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }
`;
