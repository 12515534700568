import parse from 's-expression';

export function evaluate(env, expression) {
  const parsed = parse(expression);
  return ev(env, parsed);
}

function equals(a, b) {
  // eslint-disable-next-line
  return a == b;
}

function or(...rest) {
  for (let t = 0; t < rest.length; t++) {
    if (rest[t]) {
      return true;
    }
  }
  return false;
}

function and(...rest) {
  for (let t = 0; t < rest.length; t++) {
    if (!rest[t]) {
      return false;
    }
  }
  return true;
}

function mult(...rest) {
  return rest[0] * rest[1];
}

function div(...rest) {
  return rest[0] / rest[1];
}

function minus(...rest) {
  return rest[0] - rest[1];
}

function gt(...rest) {
  return rest[0] > rest[1];
}

function lt(...rest) {
  return rest[0] < rest[1];
}

function ev(env, exp) {
  if (Array.isArray(exp)) {
    const evald = exp.map(x => ev(env, x));
    return apply(env, evald[0], evald.slice(1));
  } else if (exp instanceof String) {
    return exp;
  } else if (typeof exp === 'string') {
    const i = parseInt(exp);
    if (isNaN(i)) {
      return env[exp];
    }
    return i;
    // eslint-disable-next-line
  } else if (typeof exp === 'integer') {
    return exp;
  } else {
    // eslint-disable-next-line
    throw 'Unknown type: ' + typeof exp + exp;
  }
}

function apply(env, func, args) {
  return func(...args);
}

function defaultEnv() {
  return {
    '=': equals,
    or: or,
    and: and,
    '-': minus,
    '*': mult,
    '/': div,
    '>': gt,
    '<': lt,
  };
}

export function newEnv(vars) {
  const e = defaultEnv();
  for (const n in vars) {
    e[n] = vars[n];
  }
  return e;
}
