import findFieldWithName from '../utils/findFieldWithName';
import typeFieldValue from '../utils/typeFieldValue';

export const INDEMNITOR_FIELD_SAVE = 'INDEMNITOR_FIELD_SAVE';

export const indemnitorFieldSave = (indemnitorField, index) => ({
  type: INDEMNITOR_FIELD_SAVE,
  indemnitorField,
  index,
});

export default ({ name, value }, index) => (dispatch, getState) => {
  const {
    indemnitorFields: {
      response: { fields },
    },
  } = getState();
  if (!name) {
    return;
  }
  dispatch(
    indemnitorFieldSave(
      {
        [name]: typeFieldValue(findFieldWithName(name)(fields).type, value),
      },
      index
    )
  );
};
