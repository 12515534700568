import styled from 'styled-components';

const Spinner = styled.span`
  height: ${props => props.size};
  width: ${props => props.size};
  right: ${props => props.right};
  border-radius: 9999px;
  border-style: solid;
  border-width: ${props => props.borderWidth};
  border-color: rgba(255, 255, 255, 1) rgba(255, 255, 255, 0.2)
    rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.2);
  animation: lds-ring ${props => props.speed} linear infinite;

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

Spinner.defaultProps = {
  size: '24px',
  right: '48px',
  borderWidth: '5px',
  speed: '0.6s',
};

Spinner.displayName = 'Spinner';

export default Spinner;
