import React from 'react';
import { map, compose, pick, filter, pathOr } from 'ramda';
import { Box } from 'soot-sprites';
import { Fields, PageHeading, FormNavigation } from '../components';
import { evaluate, newEnv } from '../utils/scheme';
import {
  pickDataFromElement,
  assocValueInStateWithField,
  assocErrorWithField,
  useDocumentTitle,
} from '../utils';
import { connect } from 'react-redux';
import { createUserFormLead, saveUserField, changePage } from '../actions';

function Entry({
  pageNumber: stringPageNumber,
  form,
  userFields,
  formValidation,
  userFormLead,
  createUserFormLead,
  saveUserField,
  changePage,
  location,
  uri,
  title,
  navigate,
  ...props
}) {
  const pageIndex = Number(stringPageNumber) - 1;
  const pageName = form.response.pages[pageIndex];
  const fromReviewEditButton = pathOr(false, ['state', 'fromReview'], location);

  useDocumentTitle(pageName);

  function handleSubmit(event) {
    event.preventDefault();
    const getUserFormLead = userFormLead.response.userFormId
      ? () => new Promise(resolve => resolve())
      : createUserFormLead;

    getUserFormLead().then(() => {
      changePage({ uri, to: fromReviewEditButton ? 'review' : '' });
    });
  }
  function handleBackBtnClicked() {
    if (!pageIndex) {
      return;
    }
    navigate(`../${pageIndex}`);
  }
  return (
    <>
      <PageHeading>{form.response.pages[pageIndex]}</PageHeading>
      <Box
        as="form"
        pt={3}
        width={1}
        css="text-align: left;"
        onSubmit={handleSubmit}
        noValidate
      >
        <Fields
          onChange={event =>
            saveUserField(uri, pickDataFromElement(event.target))
          }
          fields={compose(
            map(
              compose(
                assocValueInStateWithField(userFields),
                assocErrorWithField(formValidation.response.validation_errors)
              )
            ),
            filter(f => !f.when || evaluate(newEnv(userFields), f.when))
          )(form.response.fields[pageIndex])}
        />
        <FormNavigation
          hideBackBtn={!pageIndex}
          onBackBtnClick={handleBackBtnClicked}
          loading={formValidation.inFlight}
        >
          {fromReviewEditButton ? `Return to review page` : 'Continue'}
        </FormNavigation>
      </Box>
    </>
  );
}

const mapStateToProps = pick([
  'form',
  'userFields',
  'formValidation',
  'userFormLead',
]);
const mapDispatchToProps = {
  saveUserField,
  createUserFormLead,
  changePage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Entry);
