import {
  FORM_VALIDATION_REQUESTED,
  FORM_VALIDATION_RECEIVED,
  FORM_VALIDATION_FAILED,
} from '../actions/fetchFormValidation';
import { NAVIGATION_SUCCEEDED } from '../actions/changePage';

export default function formValidation(
  state = {
    response: {
      validation_errors: [],
      indemnityError: 'No Indemnitors',
      indemnitors: [],
    },
    inFlight: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case FORM_VALIDATION_REQUESTED:
      return {
        ...state,
        inFlight: true,
        error: null,
      };
    case FORM_VALIDATION_RECEIVED:
      return {
        ...state,
        inFlight: false,
        error: null,
        response: {
          validation_errors: action.response.validation_errors,
          indemnityError: action.response.indemnitor.indemnity_error,
          indemnitors: action.response.indemnitor.feedback,
        },
      };
    case NAVIGATION_SUCCEEDED:
      return {
        ...state,
        response: {
          ...state.response,
          validation_errors: [],
        },
      };
    case FORM_VALIDATION_FAILED:
      return {
        ...state,
        inFlight: false,
        error: action.error,
      };
    default:
      return state;
  }
}
