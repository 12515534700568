import { apiRequest } from '../utils';
import { VERSION } from '../version';

export const PAYMENT_REQUESTED = 'PAYMENT_REQUESTED';
export const PAYMENT_RECEIVED = 'PAYMENT_RECEIVED';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const PAYMENT_RESET = 'PAYMENT_RESET';

export const paymentRequested = stripeToken => ({
  type: PAYMENT_REQUESTED,
  stripeToken,
});
export const paymentReceived = json => ({
  type: PAYMENT_RECEIVED,
  response: json.body,
});
export const paymentFailed = error => ({
  type: PAYMENT_FAILED,
  error,
});

export default stripeToken => (dispatch, getState) => {
  const {
    userFormLead: {
      response: { userFormId, jwt },
    },
    userFields,
    indemnitorCreate,
  } = getState();
  dispatch(paymentRequested());
  return apiRequest
    .post(
      `userform/${userFormId}`,
      {
        version: VERSION,
        form: userFields,
        stripe_token: stripeToken.id,
        indemnitors: indemnitorCreate.response.indemnitor_handles,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    )
    .then(response => {
      dispatch(paymentReceived(response));
      return response;
    })
    .catch(err => {
      dispatch(paymentFailed(err));
      throw new Error(err);
    });
};
