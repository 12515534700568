import {
  USER_FORM_ERROR,
  USER_FORM_RECEIVED,
  USER_FORM_REQUESTED,
} from '../actions/fetchUserForm';

// this reducer only really serves to keep track of the request status and the
// associated error handling
export default function restore(
  state = {
    inFlight: false,
    error: null,
    response: null,
  },
  action
) {
  switch (action.type) {
    case USER_FORM_REQUESTED:
      return {
        ...state,
        inFlight: true,
      };
    case USER_FORM_RECEIVED:
      return {
        ...state,
        inFlight: false,
        response: action.response,
      };
    case USER_FORM_ERROR:
      return {
        ...state,
        error: action.error,
        inFlight: false,
      };
    default:
      return state;
  }
}
