import { compose, unnest, map, prop } from 'ramda';

export default fieldMap =>
  compose(
    unnest,
    map(
      map(({ fields: fieldNames, name: groupName }) =>
        map(f => ({
          ...fieldMap[f],
          groupName,
        }))(fieldNames)
      )
    ),
    map(prop('groups'))
  );
