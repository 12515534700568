import { useLayoutEffect, useEffect } from 'react';

export default function useDocumentTitle(title) {
  useLayoutEffect(() => {
    document.title = title;
  }, [title]);
  useEffect(() => {
    const dataLayer = (window.dataLayer = window.dataLayer || []);
    dataLayer.push({ event: 'domReady' });
  }, [title]);
}
