import React from 'react';
import { Button, Box, theme as T, Link } from 'soot-sprites';
import { IoMdArrowForward, IoMdArrowBack } from 'react-icons/io';
import Spinner from './Spinner';

export default ({
  loading,
  LoadingComponent = Spinner,
  IconComponent = IoMdArrowForward,
  hideBackBtn,
  onBackBtnClick,
  children,
  ...props
}) => (
  <Box
    width={1}
    mt={[4, 5]}
    css={`
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    `}
  >
    <Button width={[1, '336px']} disabled={loading} {...props}>
      <span css="margin-right: 8px;">{children}</span>
      {loading ? (
        <LoadingComponent
          aria-busy="true"
          aria-live="polite"
          aria-label="Loading"
        />
      ) : (
        <IconComponent aria-hidden />
      )}
    </Button>
    <Link
      as="button"
      css={`
        color: ${T.gray(500)};
        font-size: 17px;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        ${hideBackBtn && 'display: none;'}
      `}
      type="button"
      mr={3}
      onClick={onBackBtnClick}
    >
      <IoMdArrowBack aria-hidden />
      <Box width="8px" aria-hidden />
      Back
    </Link>
  </Box>
);
