import {
  PAYMENT_REQUESTED,
  PAYMENT_RECEIVED,
  PAYMENT_FAILED,
} from '../actions/makePayment';

export default function(
  state = {
    response: null,
    inFlight: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case PAYMENT_REQUESTED:
      return {
        ...state,
        inFlight: true,
        error: null,
      };
    case PAYMENT_RECEIVED:
      return {
        ...state,
        inFlight: false,
        response: action.response,
      };
    case PAYMENT_FAILED:
      return {
        ...state,
        inFlight: false,
        error: action.error,
      };
    default:
      return state;
  }
}
