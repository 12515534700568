import styled from 'styled-components';

const InvalidFeedback = styled.div.attrs(() => ({
  'aria-live': 'polite',
  'data-testid': 'field-err-msg',
}))`
  color: ${props => props.theme.colors.error[100]};
  font-size: 14px;
  font-weight: 300;
  margin-top: 2px;
  opacity: ${props => (props.visible ? 1 : 0)};
`;

export default InvalidFeedback;
