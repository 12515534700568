import { FORM_RECEIVED } from '../actions/fetchForm';
import { NAVIGATION_SUCCEEDED, NAVIGATION_FAILED } from '../actions/changePage';
import { USER_FIELD_SAVE } from '../actions/saveUserField';
import { FORM_VALIDATION_RECEIVED } from '../actions/fetchFormValidation';
import { map, update, compose, curry, includes, unnest } from 'ramda';
import {
  staticPages,
  indemnityPages,
  ENTRY,
  INDEMNITOR,
  CREDIT,
  SIGNATURE,
} from '../routes';
import { findPageIndex } from '../utils';
import { RESTORE_PAGES } from '../actions/restorePages';

const initPage = ({ uri }) => ({
  uri,
  locked: true,
  valid: false,
  dirty: false,
});
const initPages = map(initPage);
const updatePageByIndex = curry((index, props, pages) =>
  index !== -1 ? update(index, { ...pages[index], ...props }, pages) : pages
);

export default function(
  state = {
    pages: [
      { ...ENTRY, needsValidation: true },
      ...staticPages.map(({ uri }, i) => ({
        uri,
        locked: i !== 0,
        valid: false,
        dirty: false,
      })),
    ],
  },
  action
) {
  const currentPageIndex = findPageIndex(action.uri, state.pages);

  switch (action.type) {
    case FORM_RECEIVED: {
      const entryPages = action.response.layout.map((x, i) => ({
        uri: `entry/${i + 1}`,
        locked: i !== 0,
        valid: false,
        dirty: false,
        needsValidation: true,
      }));
      return {
        ...state,
        pages: unnest([
          entryPages,
          initPages(action.response.needs_indemnity ? indemnityPages : []),
          initPages(staticPages),
        ]),
      };
    }
    case FORM_VALIDATION_RECEIVED:
      return {
        ...state,
        pages: action.updateIndemnitorPages
          ? action.response.needs_indemnity
            ? unnest([
                state.pages.filter(({ uri }) => includes('entry', uri)),
                initPages(indemnityPages),
                initPages(staticPages),
              ])
            : state.pages.filter(
                ({ uri }) =>
                  uri !== INDEMNITOR.uri &&
                  uri !== CREDIT.uri &&
                  uri !== SIGNATURE.uri
              )
          : state.pages,
      };
    case USER_FIELD_SAVE:
      return {
        ...state,
        pages: updatePageByIndex(
          currentPageIndex,
          { dirty: true, valid: false },
          state.pages
        ),
      };
    case NAVIGATION_SUCCEEDED: {
      const nextPageIndex = findPageIndex(action.toPageUri, state.pages);
      return {
        ...state,
        pages: compose(
          updatePageByIndex(nextPageIndex, { locked: false }),
          updatePageByIndex(currentPageIndex, {
            dirty: false,
            valid: true,
          })
        )(state.pages),
      };
    }
    case NAVIGATION_FAILED:
      return {
        ...state,
        pages: updatePageByIndex(
          currentPageIndex,
          { valid: false, dirty: false },
          state.pages
        ),
      };
    case RESTORE_PAGES: {
      const pageIndex = findPageIndex(action.uri, state.pages);
      return {
        ...state,
        pages: state.pages.map((x, i) => ({
          ...x,
          locked: i > pageIndex,
          valid: i <= pageIndex,
        })),
      };
    }
    default:
      return state;
  }
}
