import { apiRequest } from '../utils';

export const PARTNER_REQUEST = 'PARTNER_REQUEST';
export const PARTNER_RECEIVED = 'PARTNER_RECEIVED';
export const PARTNER_ERROR = 'PARTNER_ERROR';

export const partnerRequest = () => ({ type: PARTNER_REQUEST });
export const partnerReceived = json => ({
  type: PARTNER_RECEIVED,
  response: json.data,
});
export const partnerError = error => ({
  type: PARTNER_ERROR,
  error,
});
export default partnerId => dispatch => {
  dispatch(partnerRequest());
  return apiRequest
    .get(`partners/${partnerId}`)
    .then(response => dispatch(partnerReceived(response)))
    .catch(err => dispatch(partnerError(err)));
};
