import form from './formReducer';
import formValidation from './formValidationReducer';
import userFormLead from './userFormLeadReducer';
import userFields from './userFieldsReducer';
import partner from './partnerReducer';
import payment from './paymentReducer';
import pdf from './pdfReducer';
import page from './pageReducer';
import indemnitorFields from './indemnitorFieldsReducer';
import indemnitorUserFields from './indemnitorUserFieldsReducer';
import indemnitorValidation from './indemnitorValidationReducer';
import indemnitorCreate from './indemnitorCreateReducer';
import { combineReducers } from 'redux';
import { destroyState } from '../sessionStorage';
import { DESTROY_APPLICATION_STATE } from '../actions/destroyApplicationState';
import restore from './restoreReducer';
import restoreValidation from './restoreValidationReducer';

const appReducer = combineReducers({
  form,
  formValidation,
  userFormLead,
  userFields,
  partner,
  payment,
  pdf,
  page,
  indemnitorFields,
  indemnitorUserFields,
  indemnitorValidation,
  indemnitorCreate,
  restore,
  restoreValidation,
});

export default (_state, action) => {
  const state =
    action.type === DESTROY_APPLICATION_STATE ? destroyState() : _state;
  return appReducer(state, action);
};
