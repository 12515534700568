import { useLayoutEffect } from 'react';

// Loading animation inlined with html in order to reduce time to first pixel
// increasing user retention on slow mobile networks
export default function useDocumentTitle(condition, show) {
  useLayoutEffect(() => {
    const appLoader = document.getElementById('app-loader');
    if (appLoader && condition) {
      appLoader.style.display = show ? 'block' : 'none';
    }
  }, [condition, show]);
}
