import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer from './reducers';
import { loadState, saveState } from './sessionStorage';
import gtmMiddleware from './createGtmMiddleware';
import { pick } from 'ramda';

const middleware = [thunk, gtmMiddleware];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger({ collapsed: true }));
}
const store = createStore(reducer, loadState(), applyMiddleware(...middleware));
store.subscribe(() => {
  saveState(
    pick(
      [
        'userFormLead',
        'userFields',
        'indemnitorCreate',
        'indemnitorFields',
        'indemnitorUserFields',
        'page',
        'form',
        'partner',
        'restore',
      ],
      store.getState()
    )
  );
});
export default store;
