import {
  INDEMNITOR_CREATE_REQUESTED,
  INDEMNITOR_CREATE_RECEIVED,
  INDEMNITOR_CREATE_FAILED,
} from '../actions/createIndemnitors';
import { USER_FORM_RECEIVED } from '../actions/fetchUserForm';

export default function indemnitorCreate(
  state = {
    response: {
      indemnitor_handles: [],
    },
    inFlight: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INDEMNITOR_CREATE_REQUESTED:
      return {
        ...state,
        inFlight: true,
      };
    case INDEMNITOR_CREATE_RECEIVED:
      return {
        ...state,
        inFlight: false,
        response: action.response,
        error: null,
      };
    case INDEMNITOR_CREATE_FAILED:
      return {
        ...state,
        inFlight: false,
        error: action.error,
      };
    case USER_FORM_RECEIVED:
      return {
        ...state,
        response: {
          indemnitor_handles: action.response.indemnitor_handles,
        },
      };
    default:
      return state;
  }
}
