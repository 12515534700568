import {
  PARTNER_REQUEST,
  PARTNER_RECEIVED,
  PARTNER_ERROR,
} from '../actions/fetchPartner';

export default function partner(
  state = {
    response: {
      phone: '',
      support_email: '',
      website: '',
    },
    inFlight: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case PARTNER_REQUEST:
      return {
        ...state,
        inFlight: true,
      };
    case PARTNER_RECEIVED:
      return {
        ...state,
        inFlight: false,
        response: action.response,
      };
    case PARTNER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
