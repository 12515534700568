import { apiRequest } from '../utils';

export const USER_FORM_LEAD_POST = 'USER_FORM_LEAD_POST';
export const USER_FORM_LEAD_CREATED = 'USER_FORM_LEAD_CREATED';
export const USER_FORM_LEAD_ERROR = 'USER_FORM_LEAD_ERROR';

export const userFormLeadPost = () => ({
  type: USER_FORM_LEAD_POST,
});
export const userFormLeadCreated = response => ({
  type: USER_FORM_LEAD_CREATED,
  response,
});
export const userFormLeadError = error => ({
  type: USER_FORM_LEAD_ERROR,
  error,
});

export default () => (dispatch, getState) => {
  const { form, userFields, partner } = getState();
  dispatch(userFormLeadPost());
  return apiRequest
    .post(`partners/${partner.response.id}/bonds/${form.response.id}`, {
      form: userFields,
    })
    .then(response => dispatch(userFormLeadCreated(response.data)))
    .catch(err => dispatch(userFormLeadError(err)));
};
