import {
  findFieldWithName,
  typeFieldValue,
  findPageIndex,
  uriSegment,
} from '../utils';
import { evaluate, newEnv } from '../utils/scheme';
import { reject, compose, map, prop, filter } from 'ramda';

export const USER_FIELD_SAVE = 'USER_FIELD_SAVE';
export const USER_FIELD_DELETE = 'USER_FIELD_DELETE';

export const userFieldSave = (uri, userField) => ({
  type: USER_FIELD_SAVE,
  userField,
  uri,
});
export const userFieldDelete = names => ({
  type: USER_FIELD_DELETE,
  names,
});

export default (uri, { name, value, when }) => (dispatch, getState) => {
  const {
    form: {
      response: { fields },
    },
    userFields,
    page,
  } = getState();
  const coercedValue = typeFieldValue(
    findFieldWithName(name)(fields).type,
    value
  );
  // Prevents actions firing on form submit when fields are already stored
  if (userFields[name] === coercedValue) {
    return;
  }
  const valToSave = {
    [name]: coercedValue,
  };
  dispatch(userFieldSave(uri, valToSave));
  const updatedUserFields = { ...userFields, ...valToSave };
  // User fields should not persist in state when not visible to the user
  // Delete hidden fields
  const hiddenFieldNamesInState = compose(
    filter(x => x in updatedUserFields),
    map(prop('name')),
    reject(fieldVisible(newEnv(updatedUserFields)))
  )(fields[findPageIndex(uriSegment(uri), page.pages)]);
  if (hiddenFieldNamesInState.length) {
    dispatch(userFieldDelete(hiddenFieldNamesInState));
  }
};

const fieldVisible = env => ({ when }) => !when || evaluate(env, when);
