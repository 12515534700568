import { apiRequest } from '../utils';

export const INDEMNITOR_CREATE_REQUESTED = 'INDEMNITOR_CREATE_REQUESTED';
export const INDEMNITOR_CREATE_RECEIVED = 'INDEMNITOR_CREATE_RECEIVED';
export const INDEMNITOR_CREATE_FAILED = 'INDEMNITOR_CREATE_FAILED';

export const indemnitorCreateRequest = () => ({
  type: INDEMNITOR_CREATE_REQUESTED,
});
export const indemnitorCreateReceived = json => ({
  type: INDEMNITOR_CREATE_RECEIVED,
  response: json.data,
});
export const indemnitorCreateFail = error => ({
  type: INDEMNITOR_CREATE_FAILED,
  error,
});

export default () => (dispatch, getState) => {
  const { indemnitorUserFields, userFormLead } = getState();
  dispatch(indemnitorCreateRequest());
  return apiRequest
    .post(
      `/userform/${userFormLead.response.userFormId}/indemnitors`,
      { ...indemnitorUserFields },
      { headers: { Authorization: `Bearer ${userFormLead.response.jwt}` } }
    )
    .then(response => {
      dispatch(indemnitorCreateReceived(response));
      return true;
    })
    .catch(err => {
      dispatch(indemnitorCreateFail(err));
      return false;
    });
};
