import {
  FORM_VALIDATION_RESTORE_FAILED,
  FORM_VALIDATION_RESTORE_RECEIVED,
  FORM_VALIDATION_RESTORE_REQUESTED,
} from '../actions/fetchFormValidationRestore';

// this reducer only really serves to keep track of the request status and the
// associated error handling
export default function restoreValidation(
  state = {
    inFlight: false,
    error: null,
    response: null,
  },
  action
) {
  switch (action.type) {
    case FORM_VALIDATION_RESTORE_REQUESTED:
      return {
        ...state,
        inFlight: true,
      };
    case FORM_VALIDATION_RESTORE_RECEIVED:
      return {
        ...state,
        inFlight: false,
      };
    case FORM_VALIDATION_RESTORE_FAILED:
      return {
        ...state,
        error: action.error,
        inFlight: false,
      };
    default:
      return state;
  }
}
