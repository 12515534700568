import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Elements,
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';
import { Box, Button, theme as T, Flex, Link, Text } from 'soot-sprites';
import { makePayment } from '../actions';
import { InvalidFeedback, PageHeading, FormNavigation } from '../components';
import styled, { withTheme } from 'styled-components';
import { IoMdLock } from 'react-icons/io';
import amex from '../images/amex.svg';
import discover from '../images/discover.svg';
import mastercard from '../images/mastercard.svg';
import visa from '../images/visa.svg';
import { useDocumentTitle, term } from '../utils';
import dayjs from 'dayjs';
import media from '../styles/media';

const Card = styled.img`
  width: 48px;
  height: 48px;
  margin-left: 8px;
`;
const Section = styled.section`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${T.gray(100)};
  heading {
    text-transform: uppercase;
    font-size: 12px;
    color: ${T.gray(500)};
    white-space: nowrap;
  }
  div {
    font-size: 26px;
    font-weight: 500;
  }
  p {
    color: ${T.gray(500)};
    font-size: 13px;
  }
  ${media.sm`
    flex-flow: row nowrap;
    & > div {
      padding-right: 64px;
    }
  `}
`;

const CreditCards = () => (
  <div>
    <Card src={amex} alt="American Express" />
    <Card src={discover} alt="Discover Card" />
    <Card src={mastercard} alt="MasterCard" />
    <Card src={visa} alt="Visa" />
  </div>
);

const Field = withTheme(({ label, id, as, cardNumber, ...props }) => {
  const [invalidFeedback, setInvalidFeedback] = useState(null);
  function handleChange(event) {
    setInvalidFeedback(event.error ? event.error.message : null);
  }
  return (
    <Box mt={4} css={cardNumber && 'flex: 1; min-width: 216px;'} {...props}>
      <label
        css={`
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
        `}
        htmlFor={id}
      >
        {label}
        <input
          as={as}
          css={`
            margin-top: 2px;
            height: 48px;
            padding: 12px 16px;
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
            border: 1px solid ${T.gray(200)};
            border-radius: 4px;
            &:focus {
              outline: none;
              border-color: ${T.primary(300)};
              box-shadow: 0px 0px 2px 0px ${T.primary(300)},
                0 3px 5px 0 rgba(0, 0, 0, 0.05);
            }
          `}
          style={{
            base: {
              fontSize: '18px',
              fontSmoothing: 'antialiased',
            },
          }}
          id={id}
          onChange={handleChange}
          required
        />
      </label>
      <InvalidFeedback visible={invalidFeedback}>
        {invalidFeedback}
      </InvalidFeedback>
    </Box>
  );
});

function CheckoutForm({
  stripe,
  makePayment,
  navigate,
  form: {
    response: { name, price, valid_for, amount, quotedPrice },
  },
  payment,
  destroyApplicationState,
  title,
  userFields,
  ...props
}) {
  useDocumentTitle(title);
  let paymentRequest;
  const [loadingFeedback, setLoadingFeedback] = useState(null);
  const loading = loadingFeedback || payment.inFlight;

  useLayoutEffect(() => {
    paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: name,
        amount: price,
      },
    });
    paymentRequest.canMakePayment();
  });

  function handleSubmit(event) {
    event.preventDefault();
    setLoadingFeedback('Connecting');
    stripe
      .createToken()
      .then(({ token, error }) => {
        if (error) {
          // Fields already have stripe errors populated
          // under field; throw to trigger promise reject
          throw new Error(error);
        }
        setLoadingFeedback('Purchasing');
        return makePayment(token);
      })
      .then(() => {
        navigate('/success');
      })
      .catch(err => {
        setLoadingFeedback(null);
      });
  }

  return (
    <>
      <section
        css={`
          border: 1px solid ${T.gray(100)};
          border-radius: 4px;
          box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
          margin-bottom: 64px;
        `}
      >
        <Section
          as="heading"
          css={`
            background-color: #f9f9f9;
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
            font-size: 22px;
            font-weight: 500;
          `}
        >
          Purchase Summary
        </Section>
        <Section>
          <div>
            <heading>Bond coverage</heading>
            <div>${amount}</div>
          </div>
          <p>The bond amount is the maximum liability to the Obligee.</p>
        </Section>
        <Section>
          <div>
            <heading>Effective date</heading>
            <div>
              {dayjs(userFields.effective_date).format('MMMM DD, YYYY')}
            </div>
          </div>
          <p>Your bond will be effective starting on this date</p>
        </Section>
        <Section>
          <div>
            <heading>Bond term</heading>
            <div>{term(valid_for)}</div>
          </div>
          <p>Your bond will expire after this duration</p>
        </Section>
        <Section>
          <div>
            <heading>You pay</heading>
            <div>${quotedPrice || price}</div>
          </div>
          <p>Pay this amount to get your bond</p>
        </Section>
      </section>
      <PageHeading>
        <span css="line-height: 32px;">
          <span css="margin-right: 8px;">Secure Payment</span>
          <span css="vertical-align: middle;">
            <IoMdLock />
          </span>
        </span>
        <Text as="aside" fontSize={['12px', '14px']}>
          <Box
            mt={[3, 4]}
            as="p"
            css={`
              font-weight: 300;
              color: ${T.black};
            `}
          >
            Your privacy is important to us. All payment information is
            connected directly to our payment handler (Stripe) over a secure
            connection. Read more about our{' '}
            <Link href="https://www.selfservicesurety.com/privacy">
              Privacy & Security
            </Link>{' '}
            policies.
          </Box>
        </Text>
      </PageHeading>

      <Box
        css="text-align: left;"
        mt={5}
        width={1}
        as="form"
        onSubmit={handleSubmit}
      >
        <Flex
          css={`
            flex-flow: row wrap;
            align-items: flex-end;
          `}
        >
          <Field
            cardNumber
            as={CardNumberElement}
            id="card-number-element"
            label="Card Number"
            mr="2%"
            mt={0}
          />
          <CreditCards />
        </Flex>
        <Flex
          css={`
            flex-flow: row wrap;
          `}
          width={1}
        >
          <Field
            as={CardExpiryElement}
            width={0.49}
            id="card-expiry-element"
            label="Card Expiraton"
            mr="2%"
          />
          <Field
            as={CardCVCElement}
            width={0.49}
            id="card-cvc-element"
            label="Card CVC"
          />
        </Flex>
        <FormNavigation
          aria-label="Place order"
          css="align-items: center;"
          loading={loading}
          IconComponent={() => null}
          onBackBtnClick={() => navigate('../review')}
          data-testid="buy-btn"
          {...Button.filterProps.size.large}
        >
          {!loading ? (
            <Text css="vertical-align: middle" mr={1}>
              <IoMdLock alt="Secure" css="width: 20px; height: 20px;" />
            </Text>
          ) : null}
          <span>{loadingFeedback || 'Place Order'}</span>
        </FormNavigation>
        {payment.error ? (
          <InvalidFeedback visible={true}>
            <div>Error processing payment, please try again.</div>
            <div>
              If the problem persists, contact your card provider or try a
              different card.
            </div>
          </InvalidFeedback>
        ) : null}
      </Box>
    </>
  );
}

const InjectedCheckoutForm = injectStripe(CheckoutForm);
const PaymentPage = props => (
  <Elements>
    <InjectedCheckoutForm {...props} />
  </Elements>
);

const mapStateToProps = ({ payment, form, userFields }) => ({
  payment,
  form,
  userFields,
});
const mapDispatchToProps = { makePayment };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentPage);
