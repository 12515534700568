import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box } from 'soot-sprites';
import fetchUserForm from '../actions/fetchUserForm';
import fetchFormValidationRestore from '../actions/fetchFormValidationRestore';

function Restore({
  userFormId,
  jwt,
  fetchUserForm,
  fetchFormValidationRestore,
  form,
  partner,
  restoreValidation,
  restore,
}) {
  // fetches the userform payload
  useEffect(() => {
    fetchUserForm(userFormId, jwt);
  }, [fetchUserForm, userFormId, jwt]);

  // does an initial validate and url update based on return
  useEffect(() => {
    if (form.response.pages.length !== 0 && partner.response != null) {
      fetchFormValidationRestore();
    }
  }, [fetchFormValidationRestore, form.response, partner.response]);
  return restoreValidation.error || restore.error ? (
    <Box>
      <p>Unfortunately, we can not recall your surety bond application.</p>
      <p>
        This could be because your link has expired or has already been
        purchased.
      </p>
    </Box>
  ) : (
    <Box>
      <div
        id="app-loader"
        aria-busy="true"
        aria-live="polite"
        aria-label="Loading"
      >
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </Box>
  );
}
const mapStateToProps = ({
  userFields,
  form,
  partner,
  restore,
  restoreValidation,
}) => ({
  userFields,
  form,
  partner,
  restore,
  restoreValidation,
});
const mapDispatchToProps = {
  fetchFormValidationRestore,
  fetchUserForm,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Restore);
