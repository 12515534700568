import { apiRequest } from '../utils';
import { VERSION } from '../version';

export const PDF_REQUESTED = 'PDF_REQUESTED';
export const PDF_RECEIVED = 'PDF_RECEIVED';
export const PDF_FAILED = 'PDF_FAILED';

export const pdfRequested = () => ({
  type: PDF_REQUESTED,
});
export const pdfReceived = json => ({
  type: PDF_RECEIVED,
  response: json.data,
});
export const pdfFailed = error => ({
  type: PDF_FAILED,
  error,
});

export default () => (dispatch, getState) => {
  const {
    userFormLead: {
      response: { userFormId, jwt },
    },
    indemnitorCreate,
    userFields,
  } = getState();
  dispatch(pdfRequested());
  return apiRequest
    .post(
      `userform/${userFormId}/render`,
      {
        version: VERSION,
        form: userFields,
        indemnitors: indemnitorCreate.response.indemnitor_handles,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        responseType: 'blob',
      }
    )
    .then(response => dispatch(pdfReceived(response)))
    .catch(err => dispatch(pdfFailed(err)));
};
