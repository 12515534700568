import {
  USER_FORM_LEAD_POST,
  USER_FORM_LEAD_CREATED,
  USER_FORM_LEAD_ERROR,
} from '../actions/createUserFormLead';

export default function createUserFormLead(
  state = {
    inFlight: false,
    error: null,
    response: {
      userFormId: null,
      jwt: null,
    },
  },
  action
) {
  switch (action.type) {
    case USER_FORM_LEAD_POST:
      return {
        ...state,
        inFlight: true,
      };
    case USER_FORM_LEAD_CREATED:
      return {
        ...state,
        inFlight: false,
        response: {
          userFormId: action.response.userform_id,
          jwt: action.response.jwt,
        },
      };
    case USER_FORM_LEAD_ERROR:
      return {
        ...state,
        error: action.error,
        inFlight: false,
      };
    default:
      return state;
  }
}
