import { INDEMNITOR_FIELD_SAVE } from '../actions/saveIndemnitorField';
import { update, append, remove } from 'ramda';
import { INDEMNITOR_ADD } from '../actions/addIndemnitor';
import { INDEMNITOR_REMOVE } from '../actions/removeIndemnitor';
import { USER_FORM_RECEIVED } from '../actions/fetchUserForm';

export default function indemnitorUserFields(
  state = {
    indemnitors: [{}],
  },
  action
) {
  switch (action.type) {
    case INDEMNITOR_FIELD_SAVE:
      return {
        ...state,
        indemnitors: update(
          action.index,
          {
            ...state.indemnitors[action.index],
            ...action.indemnitorField,
          },
          state.indemnitors
        ),
      };
    case INDEMNITOR_ADD:
      return {
        ...state,
        indemnitors: append({}, state.indemnitors),
      };
    case INDEMNITOR_REMOVE:
      return {
        ...state,
        indemnitors: remove(action.index, action.index, state.indemnitors),
      };
    case USER_FORM_RECEIVED:
      return {
        ...state,
        indemnitors:
          action.response.indemnitors.length === 0
            ? [{}]
            : action.response.indemnitors,
      };
    default:
      return state;
  }
}
