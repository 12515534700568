import React from 'react';
import { connect } from 'react-redux';
import { pick } from 'ramda';
import { Container } from 'soot-sprites';
import { useInlineLoader } from '../utils';

function Expired({ partner }) {
  useInlineLoader(true, false);
  return (
    <Container css="font-size: 22px;" py={[3, 5]} px={[3, 5]}>
      <p>
        It is now safe to close this window. Please continue your checkout out
        in the same tab that you signed the indemnity agreement.
      </p>
      <p>
        Click <a href={partner.response.website}>here</a> to return to the
        homepage.
      </p>
    </Container>
  );
}

const mapStateToProps = pick(['partner']);

export default connect(mapStateToProps)(Expired);
