export default (type, value) => {
  switch (type) {
    case 'int':
      return +value;
    case 'checkbox':
      return Boolean(value);
    default:
      return value;
  }
};
