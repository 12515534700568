import { apiRequest } from '../utils';
import { VERSION } from '../version';

export const FORM_VALIDATION_REQUESTED = 'FORM_VALIDATION_REQUESTED';
export const FORM_VALIDATION_RECEIVED = 'FORM_VALIDATION_RECEIVED';
export const FORM_VALIDATION_FAILED = 'FORM_VALIDATION_FAILED';

export const formValidationRequest = () => ({
  type: FORM_VALIDATION_REQUESTED,
});
export const formValidationReceived = (json, uri, updateIndemnitorPages) => ({
  type: FORM_VALIDATION_RECEIVED,
  response: json.data,
  uri,
  updateIndemnitorPages,
});
export const formValidationFail = error => ({
  type: FORM_VALIDATION_FAILED,
  error,
});

export default uri => (dispatch, getState) => {
  const { userFields, userFormLead, indemnitorCreate, form } = getState();
  dispatch(formValidationRequest());
  return apiRequest
    .post(
      `userform/${userFormLead.response.userFormId}/validate`,
      {
        version: VERSION,
        form: userFields,
        indemnitors: indemnitorCreate.response.indemnitor_handles,
      },
      { headers: { Authorization: `Bearer ${userFormLead.response.jwt}` } }
    )
    .catch(err => {
      dispatch(formValidationFail(err));
      throw new Error(err);
    })
    .then(response => {
      dispatch(
        formValidationReceived(
          response,
          uri,
          form.response.needs_indemnity !== response.data.needs_indemnity
        )
      );
      return response;
    });
};
