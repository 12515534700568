import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { pick } from 'ramda';
import { Container } from 'soot-sprites';
import { destroyApplicationState } from '../actions';
import { useInlineLoader } from '../utils';

function Rejected({ partner, form, destroyApplicationState }) {
  useInlineLoader(true, false);
  useEffect(() => {
    destroyApplicationState();
  }, [destroyApplicationState]);
  return (
    <Container py={[3, 5]} px={[3, 5]}>
      <p>
        Unfortunately, we can not instantly approve your bond at this time. This
        does not mean you are ineligible for a bond, only that we may require
        more information for your specific request.
      </p>
      <p>
        Please contact us at{' '}
        <a href={`tel:${partner.response.phone}`}>{partner.response.phone}</a>,
        or{' '}
        <a
          href={`mailto:${partner.response.support_email}?subject=Support for ${
            form.response.id
          }`}
        >
          email us
        </a>
        , and a team member will be happy to assist you with next steps.
      </p>
    </Container>
  );
}

const mapStateToProps = pick(['partner', 'form']);
const mapDispatchToProps = { destroyApplicationState };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rejected);
