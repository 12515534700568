import { PDF_REQUESTED, PDF_RECEIVED, PDF_FAILED } from '../actions/fetchPdf';

export default function(
  state = {
    response: null,
    inFlight: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case PDF_REQUESTED:
      return {
        ...state,
        inFlight: true,
      };
    case PDF_RECEIVED:
      return {
        ...state,
        inFlight: false,
        response: action.response,
      };
    case PDF_FAILED:
      return {
        ...state,
        inFlight: false,
        error: action.error,
      };
    default:
      return state;
  }
}
