import React, { useLayoutEffect } from 'react';
import { Router } from '@reach/router';
import { useInlineLoader, lastChar } from './utils';
import { cond, match as _match, T, always } from 'ramda';

import Layout from './containers/Layout';
import Entry from './containers/Entry';
import Indemnitor from './containers/Indemnitor';
import Signature from './containers/Signature';
import Rejected from './containers/Rejected';
import Review from './containers/Review';
import Payment from './containers/Payment';
import Success from './containers/Success';
import Restore from './containers/Restore';
import Expired from './containers/Expired';
const match = regex => x => !!_match(regex, x).length;

export const ENTRY = { uri: 'entry', title: '' };
export const INDEMNITOR = { uri: 'indemnitor/0', title: 'Add Stakeholders' };
export const CREDIT = { uri: 'indemnitor/1', title: 'Request a Quote' };
export const SIGNATURE = { uri: 'signature', title: 'Sign Agreement' };
export const REVIEW = { uri: 'review', title: 'Review Bond' };
export const PAYMENT = { uri: 'payment', title: 'Place Order' };
export const SUCCESS = { uri: 'success', title: 'Purchase Successful' };
export const REJECTED = { uri: 'contact', title: 'Quote Unavailable' };
export const RESTORE = { uri: 'userform', title: 'Restoring Application...' };
export const EXPIRED = { uri: 'expired', title: 'Expired Window' };

export const staticPages = [REVIEW, PAYMENT];
export const indemnityPages = [INDEMNITOR, CREDIT, SIGNATURE];

export const CONTACT = {
  name: 'Applicant',
  pathMatch: /\/entry\/[1-4]$/,
  numPagesFn: () => 4,
  pageNumberFn: lastChar,
};
export const BOND = {
  name: 'Bond',
  pathMatch: /\/entry\/([5-9]$|[1-9][0-9])/,
  numPagesFn: pages => pages.length - 4,
  pageNumberFn: pathname => Number(lastChar(pathname)) - 4,
};
export const QUOTE = {
  name: 'Request a Quote',
  pathMatch: /\/(indemnitor|signature)/,
  numPagesFn: () => 3,
  pageNumberFn: cond([
    [match(/\/indemnitor\/0/), always(1)],
    [match(/\/indemnitor\/1/), always(2)],
    [match(/\/signature/), always(3)],
    [T, always(null)],
  ]),
};
export const ORDER = {
  name: 'Place Order',
  pathMatch: /\/(review|payment)/,
  numPagesFn: () => 2,
  pageNumberFn: cond([
    [match(/\/review/), always(1)],
    [match(/\/payment/), always(2)],
    [T, always(null)],
  ]),
};
export const categoriesWithoutIndemnity = [CONTACT, BOND, ORDER];
export const categoriesWithIndemnity = [CONTACT, BOND, QUOTE, ORDER];

const NotFound = () => {
  useInlineLoader(true, false);
  useLayoutEffect(() => {
    window.location.replace('https://selfservicesurety.com/products');
  });
  return <div />;
};

export default () => (
  <Router>
    <Layout path=":partnerId/:formId">
      <Entry path={`${ENTRY.uri}/:pageNumber`} title={ENTRY.title} />
      <Indemnitor path={`indemnitor/:pageNumber`} title={INDEMNITOR.title} />
      <Signature path={SIGNATURE.uri} title={SIGNATURE.title} />
      <Review path={REVIEW.uri} title={REVIEW.title} />
      <Payment path={PAYMENT.uri} title={PAYMENT.title} />
    </Layout>
    <Rejected path={REJECTED.uri} title={REJECTED.title} />
    <Success path={SUCCESS.uri} title={SUCCESS.title} />
    <Restore
      path={`${RESTORE.uri}/:userFormId/token/:jwt`}
      title={RESTORE.title}
    />
    <Expired path={EXPIRED.uri} title={EXPIRED.title} />
    <NotFound default />
  </Router>
);
