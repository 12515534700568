import {
  INDEMNITOR_VALIDATION_REQUESTED,
  INDEMNITOR_VALIDATION_RECEIVED,
  INDEMNITOR_VALIDATION_FAILED,
  INDEMNITOR_VALIDATION_RESET_ERRORS,
} from '../actions/fetchIndemnitorValidation';
import { INDEMNITOR_ADD } from '../actions/addIndemnitor';
import { append, map } from 'ramda';
import { USER_FORM_RECEIVED } from '../actions/fetchUserForm';

export default function indemnitorValidation(
  state = {
    response: {
      errors: [[]],
    },
    inFlight: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INDEMNITOR_VALIDATION_REQUESTED:
      return {
        ...state,
        inFlight: true,
      };
    case INDEMNITOR_VALIDATION_RECEIVED:
      return {
        ...state,
        inFlight: false,
        response: action.response,
      };
    case INDEMNITOR_VALIDATION_FAILED:
      return {
        ...state,
        inFlight: false,
        error: action.error,
      };
    case INDEMNITOR_VALIDATION_RESET_ERRORS:
      return {
        ...state,
        response: {
          ...state.response,
          errors: map(() => [], state.response.errors),
        },
      };
    case INDEMNITOR_ADD:
      return {
        ...state,
        response: {
          ...state.response,
          errors: append([], state.response.errors),
        },
      };
    case USER_FORM_RECEIVED:
      return {
        ...state,
        response: {
          ...state.response,
          errors:
            action.response.indemnitors.length === 0
              ? [[]]
              : action.response.indemnitors.map(() => {
                  return [];
                }),
        },
      };
    default:
      return state;
  }
}
