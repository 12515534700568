import { apiRequest } from '../utils';
import { userFormLeadCreated } from './createUserFormLead';
import fetchPartner from './fetchPartner';
import fetchForm from './fetchForm';

export const USER_FORM_REQUESTED = 'USER_FORM_REQUESTED';
export const USER_FORM_RECEIVED = 'USER_FORM_RECEIVED';
export const USER_FORM_ERROR = 'USER_FORM_ERROR';

export const userFormFetch = userFormId => ({
  type: USER_FORM_REQUESTED,
  userFormId,
});
export const userFormReceived = json => ({
  type: USER_FORM_RECEIVED,
  response: json.data,
});
export const userFormError = error => ({
  type: USER_FORM_ERROR,
  error,
});

export default (userFormId, jwt) => dispatch => {
  dispatch(userFormLeadCreated({ userform_id: userFormId, jwt }));
  dispatch(userFormFetch(userFormId));
  return apiRequest
    .get(`userform/${userFormId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    .then(response => {
      dispatch(userFormReceived(response));
      dispatch(fetchPartner(response.data.partner_id));
      dispatch(fetchForm(response.data.form_id));
    })
    .catch(err => dispatch(userFormError(err)));
};
