import { compose, find, propEq, propOr } from 'ramda';

export default errors => field => {
  const invalidFeedback = compose(
    propOr(null, 'message'),
    find(propEq('field', field.name))
  )(errors);
  return typeof invalidFeedback !== 'undefined'
    ? { invalidFeedback, ...field }
    : field;
};
