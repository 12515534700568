import { apiRequest } from '../utils';
import { reduce, includes, map, prop, any, equals } from 'ramda';

const errorInFields = errors => {
  const fieldNamesWithErrors = map(prop('field'), errors);
  return reduce(
    (acc, { name }) => acc || includes(name, fieldNamesWithErrors),
    false
  );
};

export const INDEMNITOR_VALIDATION_REQUESTED =
  'INDEMNITOR_VALIDATION_REQUESTED';
export const INDEMNITOR_VALIDATION_RECEIVED = 'INDEMNITOR_VALIDATION_RECEIVED';
export const INDEMNITOR_VALIDATION_FAILED = 'INDEMNITOR_VALIDATION_FAILED';
export const INDEMNITOR_VALIDATION_RESET_ERRORS =
  'INDEMNITOR_VALIDATION_RESET_ERRORS';

export const indemnitorValidationRequest = () => ({
  type: INDEMNITOR_VALIDATION_REQUESTED,
});
export const indemnitorValidationReceived = json => ({
  type: INDEMNITOR_VALIDATION_RECEIVED,
  response: json.data,
});
export const indemnitorValidationFail = error => ({
  type: INDEMNITOR_VALIDATION_FAILED,
  error,
});
export const resetIndemnitorValidationErrors = () => ({
  type: INDEMNITOR_VALIDATION_RESET_ERRORS,
});

export default () => (dispatch, getState) => {
  const { indemnitorUserFields, userFormLead, indemnitorFields } = getState();
  dispatch(indemnitorValidationRequest());
  return apiRequest
    .post(
      `userform/${userFormLead.response.userFormId}/indemnitors/validate`,
      { ...indemnitorUserFields },
      { headers: { Authorization: `Bearer ${userFormLead.response.jwt}` } }
    )
    .then(response => {
      dispatch(indemnitorValidationReceived(response));
      return pageNumber =>
        any(
          equals(true),
          map(
            error =>
              errorInFields(error)(
                indemnitorFields.response.fields[pageNumber]
              ),
            response.data.errors
          )
        );
    })
    .catch(err => {
      dispatch(indemnitorValidationFail(err));
      throw new Error(err);
    });
};
