import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, theme as T } from 'soot-sprites';
import { pdfjs, Document, Page } from 'react-pdf';
import { activationHandler } from '../utils';
import styled, { css } from 'styled-components';
import media from '../styles/media';
import {
  IoMdRemove as _IoMdRemove,
  IoMdArrowForward as _IoMdArrowForward,
  IoMdArrowBack as _IoMdArrowBack,
} from 'react-icons/io';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

const IoMdRemoveContainer = styled.div`
  position: absolute;
  right: 32px;
  top: 16px;
  z-index: 3;
  cursor: pointer;
  ${props => props.collapsed && 'display: none;'}
`;
const IoMdRemove = styled(_IoMdRemove)`
  color: ${T.primary(600)};
  height: 48px;
  width: 48px;
`;
const IoMdArrowBack = styled(_IoMdArrowBack)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;
const IoMdArrowForward = styled(_IoMdArrowForward)`
  height: 24px;
  width: 24px;
  margin-left: 8px;
`;
const Button = styled.button`
  flex: 1;
  height: 100%;
  background-color: ${T.blueGray(50)};
  border: 0;
  text-transform: uppercase;
  font-weight: 500;
  color: ${props => !props.disabled && T.primary(600)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Shade = styled.div`
  position: absolute;
  background-color: ${T.blueGray(50)};
  opacity: ${props => (props.collapsed ? 0.7 : 0)};
  z-index: 2;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;
const ShadeText = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 22px;
  font-weight: 500;
  color: ${T.primary(600)};
  z-index: 3;
  pointer-events: none;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
  ${props => !props.collapsed && 'display: none'};
  ${media.sm`
    font-size: 28px;
  `}
`;
const PeekBox = styled(Box)`
  outline: none;
  position: relative;
  overflow: hidden;
  border: 1px solid ${T.gray(100)};
  border-radius: 8px;
  max-height: 160px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
  transition: max-height 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  &:focus {
    box-shadow: 0 0 2px 1px ${T.primary(400)};
    border-color: ${T.primary(600)};
  }

  ${props =>
    props.collapsed
      ? css`
          cursor: pointer;
          &:hover,
          &:focus {
            max-height: 320px;
          }
          &:hover {
            border-color: ${T.primary(600)};
          }
        `
      : css`
          max-height: 9999px;
        `}
`;
const AnimatedBox = styled(Box)`
  min-height: 80px;
  user-select: none;
  filter: blur(3px);
  transition: filter 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${props =>
    props.collapsed
      ? css`
          &:hover,
          &:focus {
            filter: none;
          }
        `
      : css`
          filter: none;
        `}
`;

const pdfAsyncStatus = (pdf, name) =>
  pdf.error
    ? `Error loading bond preview`
    : pdf.inFlight
    ? `Loading...`
    : `View a preview of your ${name} Bond`;

function Pdf({ userFields, pdf, formName, ...props }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const containerRef = useRef(null);
  const { onClick, onKeyDown } = activationHandler(e => {
    setCollapsed(c => !c);
  });

  useEffect(() => {
    const onResize = () => {
      setWidth(containerRef.current.clientWidth);
    };
    window.addEventListener('resize', onResize, true);
    return () => window.removeEventListener('resize', onResize, true);
  }, [containerRef, setWidth]);

  useEffect(() => {
    setWidth(containerRef.current.clientWidth);
  }, [containerRef, setWidth]);

  return (
    <PeekBox
      aria-label="View PDF Preview"
      aria-expanded={!collapsed}
      mt={[4, 5]}
      tabIndex={collapsed ? 0 : undefined}
      collapsed={collapsed}
      onKeyDown={event => collapsed && onKeyDown(event)}
    >
      <IoMdRemoveContainer
        aria-label="Minimize"
        onClick={onClick}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex="0"
        collapsed={collapsed}
      >
        <IoMdRemove aria-hidden />
      </IoMdRemoveContainer>
      <ShadeText collapsed={collapsed}>
        {pdfAsyncStatus(pdf, formName)}
      </ShadeText>
      <Shade aria-hidden collapsed={collapsed} />
      <AnimatedBox
        width={1}
        ref={containerRef}
        collapsed={collapsed}
        onClick={onClick}
        {...props}
      >
        <Document
          aria-label="PDF Document"
          file={pdf.response}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          <Page width={width} pageNumber={pageNumber} />
        </Document>
      </AnimatedBox>
      {numPages > 1 ? (
        <Flex
          width={1}
          css={css`
            justify-content: space-around;
            flex-flow: row nowrap;
            position: absolute;
            z-index: 3;
            bottom: 0;
            align-items: center;
            border-top: 1px solid ${T.gray(100)};
            height: 48px;
            ${collapsed && 'display: none;'}
          `}
        >
          <Button
            css={`
              border-right: 1px solid ${T.gray(100)};
            `}
            onClick={() => setPageNumber(pageNumber - 1)}
            disabled={pageNumber === 1}
          >
            <IoMdArrowBack aria-hidden />
            <span>Previous page</span>
          </Button>
          {/* <span
            css={`
              text-transform: uppercase;
              font-weight: 500;
              color: ${T.gray(500)};
            `}
          >
            Page {pageNumber} of {numPages}
          </span> */}
          <Button
            css={``}
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={pageNumber === numPages}
          >
            <span>Next page</span>
            <IoMdArrowForward aria-hidden />
          </Button>
        </Flex>
      ) : null}
    </PeekBox>
  );
}

Pdf.displayName = 'Pdf';
export default Pdf;
