import { apiRequest } from '../utils';
import fetchIndemnitors from './fetchIndemnitors';

export const FORM_REQUESTED = 'FORM_REQUESTED';
export const FORM_RECEIVED = 'FORM_RECEIVED';
export const FORM_FAILED = 'FORM_FAILED';

export const formRequest = formId => ({ type: FORM_REQUESTED, formId });
export const formReceive = json => ({
  type: FORM_RECEIVED,
  response: json,
});
export const formFail = error => ({
  type: FORM_FAILED,
  error,
});

export default formId => dispatch => {
  dispatch(formRequest(formId));
  return apiRequest
    .get(`bonds/${formId}`)
    .then(response => {
      response.data.needs_indemnity && dispatch(fetchIndemnitors());
      return dispatch(formReceive(response.data));
    })
    .catch(err => dispatch(formFail(err)));
};
