import { css } from 'styled-components';

const [smPx, lgPx] = ['768px', '1170px'];

const lg = (...args) => css`
  @media screen and (min-width: ${lgPx}) {
    ${css(...args)};
  }
`;
const md = (...args) => css`
  @media screen and (min-width: 841px) {
    ${css(...args)};
  }
`;
const sm = (...args) => css`
  @media screen and (min-width: ${smPx}) {
    ${css(...args)};
  }
`;
const xs = (...args) => css`
  @media screen and (max-width: 767px) {
    ${css(...args)};
  }
`;

export default { xs, sm, md, lg };
