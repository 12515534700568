import { apiRequest } from '../utils';
import { reduce, includes, map, prop } from 'ramda';
import * as R from 'ramda';
import { VERSION } from '../version';
import { navigate } from '@reach/router';
import restorePages from './restorePages';
import { formValidationReceived } from './fetchFormValidation';
import { ENTRY, REVIEW, SIGNATURE } from '../routes';

export const FORM_VALIDATION_RESTORE_REQUESTED =
  'FORM_VALIDATION_RESTORE_REQUESTED';
export const FORM_VALIDATION_RESTORE_RECEIVED =
  'FORM_VALIDATION_RESTORE_RECEIVED';
export const FORM_VALIDATION_RESTORE_FAILED = 'FORM_VALIDATION_RESTORE_FAILED';

export const formValidationRestoreRequest = () => ({
  type: FORM_VALIDATION_RESTORE_REQUESTED,
});

export const formValidationRestoreFail = error => ({
  type: FORM_VALIDATION_RESTORE_FAILED,
  error,
});

const errorInFields = errors => {
  const fieldNamesWithErrors = map(prop('field'), errors);
  return reduce(
    (acc, { name }) => acc || includes(name, fieldNamesWithErrors),
    false
  );
};

export default () => (dispatch, getState) => {
  const {
    userFields,
    userFormLead,
    form,
    partner,
    indemnitorCreate,
  } = getState();
  dispatch(formValidationRestoreRequest());
  return apiRequest
    .post(
      `userform/${userFormLead.response.userFormId}/validate`,
      {
        version: VERSION,
        form: userFields,
        indemnitors: indemnitorCreate.response.indemnitor_handles,
      },
      { headers: { Authorization: `Bearer ${userFormLead.response.jwt}` } }
    )
    .catch(err => {
      dispatch(formValidationRestoreFail(err));
      throw new Error(err);
    })
    .then(response => {
      // this is a hacky way to communicate cross tabs
      localStorage.setItem('origin', 'restored');
      let uri = `${ENTRY.uri}/1`;
      if (response.data.validation_errors.length === 0) {
        switch (response.data.indemnitor.indemnity_error) {
          case '': {
            uri = `${REVIEW.uri}`;
            break;
          }
          case 'awaiting_signature': {
            uri = `${SIGNATURE.uri}`;
            break;
          }
          case 'indemnitors_required': {
            uri = `${SIGNATURE.uri}/0`;
            break;
          }
          default: {
            // fallthrough to first page
            break;
          }
        }
      } else {
        const pageValid = page =>
          !errorInFields(response.data.validation_errors)(page);
        const isFalse = x => R.equals(x, false);
        const errorIndex = R.findIndex(
          isFalse,
          R.map(pageValid, form.response.fields)
        );
        // go to error index on entry
        uri = `${ENTRY.uri}/${errorIndex}`;
      }

      // bypass page reducer for nav since restore page isn't technically in the
      // workflow
      navigate(`/${partner.response.id}/${form.response.id}/${uri}`);
      dispatch(restorePages(uri));
      dispatch(
        formValidationReceived(
          response,
          uri,
          form.response.needs_indemnity !== response.data.needs_indemnity
        )
      );
    });
};
