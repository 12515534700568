import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Box, theme as T } from 'soot-sprites';
import media from '../styles/media';
import { categoriesWithIndemnity, categoriesWithoutIndemnity } from '../routes';
import { findIndex } from 'ramda';

const circled = (size, color) => css`
  display: inline-block;
  border-radius: 50%;
  border: 2px solid ${T.black};
  text-align: center;
  vertical-align: top;
  line-height: ${size - 2}px;
  height: ${size}px;
  width: ${size}px;
  border-color: ${color};
  color: ${color};
`;
const PageTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  margin-left: 8px;
  padding-right: 8px;
  ${media.sm`
    font-size: 16px;
    margin-left: 16px;
    padding-right: 16px;
  `}
`;
const navTextColor = props => (props.active ? T.primary(400) : T.gray(200));
const StepNumber = styled.span`
  font-size: 14px;
  font-weight: 600;
  ${circled(24, navTextColor)};
  ${media.sm`
    ${circled(36, navTextColor)};
    font-size: 18px;
  `}
`;
const Nav = styled(Box).attrs({ as: 'nav' })`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  overflow: auto;
  align-items: center;
`;
const Line = styled.div`
  background-color: ${props =>
    props.completed ? T.primary(400) : T.gray(100)};
  height: 2px;
`;
function Progress({
  partnerId,
  formId,
  form,
  page,
  changePage,
  location,
  ...props
}) {
  const categories = form.response.needs_indemnity
    ? categoriesWithIndemnity
    : categoriesWithoutIndemnity;
  const activeIndex = findIndex(
    ({ pathMatch }) => location.pathname.match(pathMatch),
    categories
  );
  const { name: activeName } = categories[activeIndex];
  return (
    <Nav {...props}>
      {categories.map(({ name }, i, arr) => {
        const isActive = name === activeName;
        const completed = activeIndex > i;
        return (
          <Fragment key={name}>
            <Box
              css={`
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipses;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
              `}
              aria-label={`Step ${i + 1}: ${name}`}
              aria-current={isActive ? true : undefined}
            >
              <StepNumber active={isActive || completed}>{i + 1}</StepNumber>
              {isActive ? <PageTitle>{name}</PageTitle> : null}
            </Box>
            {i !== arr.length - 1 ? (
              <Line css="flex: 1;" completed={completed} />
            ) : null}
          </Fragment>
        );
      })}
    </Nav>
  );
}

Progress.displayName = 'Progress';
export default Progress;
