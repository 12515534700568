export default {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    offBlack: '#313334',
    gray: {
      50: '#FCFCFC', // bgLightSecondary
      100: '#EAEAEA', // offWhite, gallery
      200: '#E5E5E5',
      300: '#A7A7A7', // textShareSave
      400: '#A5A5A5', // buttonBorderTertiary
      500: '#5F5F5F', // textSub
    },
    primary: {
      50: '#BEC2D9', // blueHaze, brandTextBreadcrumb
      100: '#D7E9FF', // brandTertiary
      200: '#94C1FF', // brandSecondary
      a200: '#80B2FF',
      300: '#258BFC', // dodgerBlue, brandButton
      400: '#1069F3', // blueRibbon, brandEmphasis
      500: '#106DFC', // blueRibbonSecondary, brandButtonSecondary
      600: '#116FFF', // brandPrimary, blueRibbonTertiary, brandLink
      700: '#0D5CD8', // denim, brandButtonBorder
      800: '#161D2D', // bgFooter, brandDark
    },
    error: {
      50: '#FFBABA',
      100: '#D8000C',
    },
    blueGray: {
      50: '#F6F7FB', // bgLight, bgHeader, brandWhite
      100: '#9CA3A7', // bgGray
      200: '#818B97', // brandGrey
    },
    gradient: {
      hero: ['#FFFFFF', '#F6F7FB'],
      button: ['#106EFD', '#0F63E5'],
    },
  },
  maxWidth: '720px',
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
  breakpoints: ['768px', '1170px'],
};
